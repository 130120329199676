import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {name: "", roles: [], authToken: ""},
    reducers: {
        updateUser(state, action) {
            state.name = action.payload.name
            state.roles = action.payload.roles
            state.authToken = action.payload.authToken
        }
    }
});

export default userSlice.reducer;
export const { updateUser } = userSlice.actions
export const getActiveRoles = (state) => state.user.roles
