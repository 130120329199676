import PropTypes from 'prop-types'
import SideBar from '../components/Sidebar';
import {Link} from "react-router-dom";

const Main = (props) => (
    <div id="wrapper">
        <header id="header">
            <h1><Link to="/">Chris Hardin</Link></h1>
            <nav className="links">
                <ul>
                    <li><Link to={`/about`}>About</Link></li>
                    <li><Link to={`/resume`}>Resume</Link></li>
                    <li><Link to={`/roadmap`}>Roadmap</Link></li>
                    <li><Link to={`/login`}>Login</Link></li>
                </ul>
            </nav>
        </header>
        <div id="main">
            {props.children}
        </div>
        {/*{props.fullPage ? null : <SideBar />}*/}
        <SideBar />

    </div>    
);

Main.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fullPage: PropTypes.bool,
}

Main.defaultProps = {
    children: null,
    fullPage: false,
}
  
  export default Main;
