import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub} from "@fortawesome/free-brands-svg-icons/faGithub";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons/faLinkedin";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {useSelector} from "react-redux";

const { PUBLIC_URL } = process.env

const SideBar = () => {
    const user = useSelector(state => state.user.name)


    return (
        <section id="sidebar">
            <section id="intro">
                <Link to="/" className="logo">
                    <img src={`${PUBLIC_URL}/images/c.png`} />
                </Link>
                <header>
                    <h2><Link to="/">Chris Hardin</Link></h2>
                    <p>Software Engineer</p>
                </header>
            </section>

            <section className="blurb">
                <p>I'm Chris! I'm a senior software engineer working at Genius Sports. When I'm not working,
                    I'm probably playing sand volleyball or hanging out with my wife and my dog, Hobbes.</p>
            </section>

            <section id="footer">
                <ul className="icons">
                    <li key="LinkedIn">
                        <a href="https://www.linkedin.com/in/hardinchristopher/"><FontAwesomeIcon icon={faLinkedin}/></a>
                    </li>
                    <li key="Github">
                        <a href="https://github.com/thatschris"><FontAwesomeIcon icon={faGithub}/></a>
                    </li>
                    {/*<li key="Email">*/}
                    {/*    <a href="mailto:chrishusky27@gmail.com"><FontAwesomeIcon icon={faEnvelope}/></a>*/}
                    {/*</li>*/}
                    <li>{user}</li>
                </ul>
                <p className="copyright">Chris Hardin
                    {/*<a href="mailto:chrishusky27@gmail.com">chrishusky27@gmail.com</a>*/}
                </p>
            </section>
        </section>
    );
}

export default SideBar;
