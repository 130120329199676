import Main from "../../layouts/Main";
import {
    Box,
    Grid, IconButton, InputAdornment, TextField
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {createTask, deleteTask, editTask, getTasksDemo, selectAllTasks} from "./RoadmapSlice";
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import {useEffect, useState} from "react";
import axios from "axios";
import {DataGrid} from "@mui/x-data-grid";
import {AddCircle} from "@mui/icons-material";

const Roadmap = (props) => {

    const [taskName, setTaskName] = useState('')

    const dispatch = useDispatch()
    const tasks = useSelector(selectAllTasks)

    useEffect(() => {
        dispatch(getTasksDemo())
    }, [])

    const isAdmin = () => props.isAdmin


    const createTaskOnClick = () => {
        if (isAdmin()) {
            axios.post(process.env.REACT_APP_BASE_URL + "/admin/tasks/create", {name: taskName}, {withCredentials: true})
                .then(res => {
                    dispatch(createTask(res.data.data))
                    setTaskName("")
                })
        } else {
            dispatch(createTask({name: taskName, status: "To Do", id: (tasks.length > 0) ? tasks[tasks.length - 1].id + 1 : 1}))
            setTaskName("")
        }

    }

    const deleteTaskOnClick = (id) => {
        if (isAdmin()) {
            axios.get(process.env.REACT_APP_BASE_URL + "/admin/tasks/delete/" + id, {withCredentials: true})
                .then(() => {
                    dispatch(deleteTask(id))
                })
        } else {
            dispatch(deleteTask(id))
        }

    }

    const taskStatusChangeOnClick = (task, newStatus) => {
        if (isAdmin()) {
            axios.put(process.env.REACT_APP_BASE_URL + "/admin/tasks/update/" + task.id,
                {name: task.name, id: task.id, status: newStatus}, // Todo this feels sloppy
                {withCredentials: true})
                .then(res => {
                    dispatch(editTask(res.data.data))
                })
        } else {
            dispatch(editTask({name: task.name, id: task.id, status: newStatus}))
        }

    }

    const TaskActionButtons = (row) => {
        return (
            <div style={{width: '100%'}}>
                <IconButton style={{float: "right"}} onClick={() => deleteTaskOnClick(row.id)}><DeleteIcon /></IconButton>
                <IconButton style={{float: "right"}} onClick={() => taskStatusChangeOnClick(row.row, "COMPLETED")}><CheckCircleIcon /></IconButton>
                <IconButton style={{float: "right"}} onClick={() => taskStatusChangeOnClick(row.row, "IN_PROGRESS")}><PendingIcon /></IconButton>
                <IconButton style={{float: "right"}} onClick={() => taskStatusChangeOnClick(row.row, "TODO")}><TurnLeftIcon /></IconButton>
            </div>
        )
    }

    const taskColumns = [
        {
            field: 'id',
            headerName: "Id",
            hideSortIcons: true,
            flex: 1
        },
        {
            field: 'name',
            headerName: "Task",
            hideSortIcons: true,
            flex: 1
        },
        {
            field: 'status',
            headerName: "Status",
            hideSortIcons: true,
            flex: 1
        },
        {
            field: 'actions',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: TaskActionButtons
        }
    ];



    return (
        <Main>
            <article className="post" id="roadmap">
                <header>
                    <div className="title">
                        <h2>Roadmap</h2>
                        <p>No technology tutorial would be complete without a Todo list example. I initially created this
                        page to learn Redux, Axios, and more sophisticated React concepts. For the moment, this page will serve as a way to
                        document and track the current features that I'm working on. My long term vision is to build out
                        a 'JIRA clone' to track long term project goals.</p>
                    </div>
                </header>
            </article>

            <Grid container rowSpacing={4} spacing={12}>
                <Grid item xs={12}>
                    <TextField
                        label={"Add Task"}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <IconButton onClick={createTaskOnClick}><AddCircle /></IconButton>
                                </InputAdornment>
                            )
                        }}
                        value={taskName}
                        onChange={(event) => setTaskName(event.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5
                                }
                            },
                            sorting: {
                                sortModel: [{field: 'id', sort: 'desc'}]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    id: false
                                }
                            }
                        }}
                        pageSizeOptions={[5]}
                        columns={taskColumns}
                        rows={tasks}>
                    </DataGrid>

                </Grid>

            </Grid>
        </Main>
    );
}

export default Roadmap;
