import {createAsyncThunk, createReducer, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {useDispatch} from "react-redux";

export const getTasksDemo = createAsyncThunk(
    "tasks/demo",
    async () => {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/tasks/demo", {withCredentials: true})
        return response.data.data
    })



const roadmapSlice = createSlice({
    name: "roadmap",
    initialState: {
        entities: [],
        loading: false
    },
    reducers: {
        createTask: (state, action) => {
            state.entities.push(action.payload)
        },
        deleteTask: (state, action) => {
            const i = state.entities.findIndex(e => e.id === action.payload)
            state.entities.splice(i, 1)
        },
        editTask: (state, action) => {
            state.entities =  state.entities.map(item => {
                if (item.id === action.payload.id) {
                    return {...action.payload}
                } else {
                    return item
                }
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTasksDemo.fulfilled, (state, action) => {
            state.entities = action.payload
            state.loading = false
        });
        builder.addCase(getTasksDemo.pending, (state) => {
            state.loading = true
        });
        builder.addCase(getTasksDemo.rejected, (state) => {
            state.loading = false
        });
    }

});

export default roadmapSlice.reducer
export const { createTask, deleteTask, editTask } = roadmapSlice.actions
export const selectAllTasks = (state) => state.roadmap.entities;
