import {combineReducers, configureStore} from "@reduxjs/toolkit";
import roadmapReducer from "./features/roadmap/RoadmapSlice"
import userReducer from "./features/user/UserSlice"


const rootReducer = combineReducers({
    roadmap: roadmapReducer,
    user: userReducer
})
export default configureStore({
    reducer: rootReducer
})
