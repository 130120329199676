import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Main from './layouts/Main';
import './static/css/main.scss'
import Roadmap from "./features/roadmap/Roadmap";
import {getActiveRoles} from "./features/user/UserSlice";
import {useSelector} from "react-redux";

function App() {
  console.log(process.env)

  const isAdmin = useSelector(getActiveRoles).includes("ADMIN")
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   axios.post(process.env.REACT_APP_BASE_URL + "/login?username=Demo&password=password", {}, {withCredentials: true})
  //       .then(res => dispatch(updateUser(res.data)))
  //       .catch(res => console.warn("sign in failed"));
  // }, [])

  const Resume = lazy(() => import('./features/Resume'));
  const Home = lazy(() => import('./features/Home'));
  const About = lazy(() => import('./features/About'));
  const Login = lazy(() => import('./features/Login'));

  return (
    <BrowserRouter>
      <Suspense fallback={<Main />}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/resume" element={<Resume />}></Route>
          <Route path="/roadmap" element={<Roadmap isAdmin={isAdmin}/>}></Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>
      </Suspense>
    
    </BrowserRouter>
  );
}

export default App;
